export const datasetOverview = [
  {
    groupName: 'Air Temperature',
    elements: [
      {
        propName: 'minAirTemp',
        label: 'T° Air min'
      }, {
        propName: 'maxAirTemp',
        label: 'T° Air max'
      }, {
        propName: 'avgAirTemp',
        label: 'T° Air avg'
      }
    ]
  },
  {
    groupName: 'Track Temperature',
    elements: [
      {
        propName: 'minTrackTemp',
        label: 'T° Track min'
      }, {
        propName: 'maxTrackTemp',
        label: 'T° Track max'
      }, {
        propName: 'avgTrackTemp',
        label: 'T° Track avg'
      }
    ]
  },
  {
    groupName: 'Humidity',
    elements: [
      {
        propName: 'minHumidity',
        label: 'Hu min'
      }, {
        propName: 'maxHumidity',
        label: 'Hu max'
      }, {
        propName: 'avgHumidity',
        label: 'Hu avg'
      }
    ]
  },
  {
    groupName: 'Pressure',
    elements: [
      {
        propName: 'minPressure',
        label: 'P min'
      }, {
        propName: 'maxPressure',
        label: 'P max'
      }, {
        propName: 'avgPressure',
        label: 'P avg'
      }
    ]
  },
  {
    groupName: 'Wind speed',
    elements: [
      {
        propName: 'minWindSpeed',
        label: 'Wind speed min'
      }, {
        propName: 'maxWindSpeed',
        label: 'Wind speed max'
      }, {
        propName: 'avgWindSpeed',
        label: 'Wind speed avg'
      }
    ]
  },
  {
    groupName: 'Gust wind',
    elements: [
      {
        propName: 'minWindGust',
        label: 'Gust min'
      }, {
        propName: 'maxWindGust',
        label: 'Gust max'
      }, {
        propName: 'avgWindGust',
        label: 'Gust avg'
      }
    ]
  },
  {
    groupName: 'Rain Intensity',
    elements: [
      {
        propName: 'minRainIntensity',
        label: 'Rain intensity min'
      }, {
        propName: 'maxRainIntensity',
        label: 'Rain intensity max'
      }, {
        propName: 'sumRainIntensity',
        label: 'Rain total'
      }
    ]
  }
]

export const datasetCompare = [
  {
    propName: 'avgAirTemp',
    label: 'T° Air'
  },
  {
    propName: 'avgTrackTemp',
    label: 'T° Track'
  },
  {
    propName: 'sumRainIntensity',
    label: 'Rain total'
  },
  {
    propName: 'avgWindSpeed',
    label: 'Wind speed avg'
  },
  {
    propName: 'avgWindGust',
    label: 'Gust avg'
  },
  {
    propName: 'mainWindDirection',
    label: 'Wind main dir'
  },
  {
    propName: 'avgHumidity',
    label: 'Humidity'
  },
  {
    propName: 'avgPressure',
    label: 'Pressure'
  }
]

export const datasetWeatherStation = [
  {
    propName: 'station1',
    label: 'Station 1'
  },
  {
    propName: 'station2',
    label: 'Station 2'
  },
  {
    propName: 'station3',
    label: 'Station 3'
  }
]
