import { WidgetType } from '@/components/ui/WidgetSettings/definition'

export class VueGridItem {
  x: number;
  y: number;
  w: number;
  h: number;
  i: number;
  type?: WidgetType;
  title: string;
  params?: Record<string, string | boolean | number | Record<string, any>>
}

export class ChartDataset {
  datasetAirTemp: boolean;
  datasetTrackTemp: boolean;
  datasetWindAvg: boolean;
  datasetWindGust: boolean;
  datasetHumidity: boolean;
  datasetPressure: boolean;
  datasetRainIntensity: boolean;
  datasetWindDirection: boolean;
}

export type DatasetScale = 'temperature' | 'wind' | 'humidity' | 'pressure' | 'rain'

export const datasetsConfiguration: Record<string, {
  datasetKey: string;
  datasetScale: DatasetScale;
  datasetBindings: {
    index: number;
    rangeMode?: string;
    rangeMin?: number;
    rangeMax?: number | Function;
    rangeMin2?: number;
    rangeMax2?: number;
    displayMin?: boolean;
    displayAvg?: boolean;
    displayMax?: boolean;
}[];
}> = {
  datasetAirTemp: {
    datasetKey: 'datasetTemperature',
    datasetScale: 'temperature',
    datasetBindings: [{
      index: 0
    }, {
      index: 2
    }]
  },
  datasetTrackTemp: {
    datasetKey: 'datasetTemperature',
    datasetScale: 'temperature',
    datasetBindings: [{
      index: 1
    }, {
      index: 3
    }]
  },
  datasetWindAvg: {
    datasetKey: 'datasetWind',
    datasetScale: 'wind',
    datasetBindings: [{
      index: 0
    }, {
      index: 2
    }]
  },
  datasetWindGust: {
    datasetKey: 'datasetWind',
    datasetScale: 'wind',
    datasetBindings: [{
      index: 1
    }, {
      index: 3
    }]
  },
  datasetHumidity: {
    datasetKey: 'datasetHuPressure',
    datasetScale: 'humidity',
    datasetBindings: [{
      index: 0,
      rangeMode: 'tozero'
    }]
  },
  datasetPressure: {
    datasetKey: 'datasetHuPressure',
    datasetScale: 'pressure',
    datasetBindings: [{
      index: 1,
      rangeMode: 'tozero'
    }]
  },
  datasetRainIntensity: {
    datasetKey: 'datasetRain',
    datasetScale: 'rain',
    datasetBindings: [{
      index: 0,
      rangeMin: 0,
      rangeMax: (dataset) => dataset.maxHumidity + dataset.humidityAddition,
      rangeMode: 'tozero'
    }]
  },
  datasetWindDirection: {
    datasetKey: 'datasetWindRoseDirection',
    datasetScale: 'wind',
    datasetBindings: [{
      index: 0,
      rangeMode: 'tozero',
      displayMin: false,
      displayAvg: false,
      displayMax: false
    }]
  }
}
