




































































































































































































































































































































































import { fetchForecastDataGlobal, vrsStore } from '@/store'

import { AppQS } from '@/store/app/definitions'
import { convertDate, stringifyDate } from '@/helpers/dates'
import { vrsStoreEvent } from '@/store/event/store'

import Timeline from '@/components/ui/Timeline/Timeline.vue'
import Player from '@/components/ui/Player/Player.vue'
import Loader from '@/components/ui/Loader/Loader.vue'
import RainInformation from '@/components/data/forecast/rain/RainInformation.vue'
import WeatherStationListing from '@/components/data/weatherstation/WeatherStationListing.vue'
import ServiceInformation from '@/components/data/information/ServiceInformation.vue'
import Button from '@/components/ui/Button/Button.vue'
import Chart from '@/components/data/weatherstation/Chart.vue'
import ForecastMix from '@/components/data/forecast/ForecastMix.vue'
import WidgetSettings from '@/components/ui/WidgetSettings/WidgetSettings.vue'
import Overview from '@/components/data/weatherstation/Overview/Overview.vue'
import CompareSessions from '@/components/data/weatherstation/CompareSessions/CompareSessions.vue'
import WrapperMapLegend from '@/components/map/WrapperMapLegend/WrapperMapLegend.vue'
import preferences from '@/services/preferences'

import { GridItem, GridLayout } from 'vue-grid-layout'
import { Dictionary } from 'vue-router/types/router'
import { WeatherEvent } from '@/store/event/definitions'
import { fetchRadarBbox } from '@/store/time/store'
import { getDatasets } from '@/components/data/weatherstation/helper'
import { fetchWeatherStationDataFilteredByDate } from '@/store/weatherstation/store'
import { ChartDataset, datasetsConfiguration, VueGridItem } from './definition'
import { filterForecastDataByDate } from '@/store/forecast/helpers'
import { selectionSessions } from '@/views/ClimateRecap/helper'
import { DefaultSettingCompareSession } from '@/components/data/weatherstation/CompareSessions/definitions'
import { typesWidgets } from '@/components/ui/WidgetSettings/helper'
import { setChartDatasetRanges } from '@/views/CustomDashboard/helper'

const defaultColNum = 24
const defaultRowHeight = 15

const seriesColor = [
  '#DD66D8',
  // gust: '#F1C4E6' },
  '#55C116',
  // gust: '#B8F3A3' },
  '#00B3FF'
  // gust: '#77e7fd' }
]

export default {
  name: 'CustomDashboard',
  components: {
    'ui-timeline': Timeline,
    'ui-player': Player,
    'ui-loader': Loader,
    'ui-button': Button,
    'wrapper-map-legend': WrapperMapLegend,
    'forecast-rain-information': RainInformation,
    'weather-station-listing': WeatherStationListing,
    'service-information': ServiceInformation,
    'forecast-mix': ForecastMix,
    'vgl-grid-layout': GridLayout,
    'vgl-grid-item': GridItem,
    'widget-settings': WidgetSettings,
    'ground-station-chart': Chart,
    'event-overview': Overview,
    'compare-sessions': CompareSessions
  },
  props: {
    section: {
      type: String,
      default: 'forecast'
    },
    dashboardIndex: {
      type: String,
      default: '0'
    }
  },
  data () {
    return {
      stateApp: vrsStore.state.app,
      stateAuth: vrsStore.state.auth,
      stateConfig: vrsStore.state.config,
      stateForecast: vrsStore.state.forecast,
      stateInformation: vrsStore.state.information,
      stateEvent: vrsStore.state.event,
      stateMessage: vrsStore.state.message,
      stateWeatherStation: vrsStore.state.weatherStation,
      stateGeneral: vrsStore.state,
      stateTime: vrsStore.state.time,
      stateReplaySettings: vrsStore.state.app.data.replaySettings,

      dataPlayerRainForecast: null, // Link to Position in Player if Exist
      dataPlayerInformation: null, // Link to Position in Player if Exist

      currentWidgetInEdition: null,
      dashboardStatus: 'read', // read - update
      displayTimeline: true,

      // vue-grid-layout settings
      colNum: defaultColNum,
      rowHeight: defaultRowHeight,

      frameRate: 4,
      isPlaying: false,
      timeoutId: null,
      animationLengths: [15, 30, 60, 120, 180, 240, 300, 360]
    }
  },
  computed: {
    // currentTime,
    // selectedRangeLocal,
    // timesToDisplayUTC
    ...vrsStore.modules.time.computed,
    allDataTimeRangesLocal: vrsStore.computed.allDataTimeRangesLocal,
    defaultSelectedCompareSession (): DefaultSettingCompareSession {
      return {
        ...selectionSessions({
          defaultSessionA: null,
          defaultSessionB: null,
          defaultStationA: 'all',
          defaultStationB: 'all'
        }, this.stateEvent.sessionsWithStat)
      }
    },

    widgetFromDashboard () {
      // Avoid reference issue between currentDashboard and currentWidgetInEdition
      // Allows direct visualisation of changes when editing the widget when creating widget
      if (this.currentDashboard?.layout.length > 0 && this.currentWidgetInEdition) return this.currentDashboard.layout[this.currentWidgetInEdition.i]
      return null
    },

    isPlayerInDashboard (): boolean {
      if (this.currentDashboard?.layout.length > 0) return this.currentDashboard?.layout.some(widget => widget.type === 'player')
      return false
    },

    hourlyData () {
      return vrsStore.state.forecast.data.hourly?.data?.slice(0, 1)
    },

    flattenData () {
      if (!this.hourlyData || !vrsStore.state.forecast.data.hourly?.flattenData) return []
      return vrsStore.state.forecast.data.hourly?.flattenData?.slice(0, this.hourlyData[0]?.data?.length || 0) || []
    },

    currentDashboard () {
      return vrsStore.state.auth.data.user?.dashboards[this.dashboardIndex] || { label: 'No dashboard', layout: [] }
    },

    isDashboardEditMode () {
      return this.dashboardStatus === 'update'
    },

    /**
     * Leaflet / Mapbox map settings
     */
    mapComponentToUse (): string {
      return vrsStore.modules.app.computed.mapComponentToUse()
    },

    animationLength () {
      return this.animationLengths.indexOf(vrsStore.state.app.data.animationLength) !== -1 ? vrsStore.state.app.data.animationLength : 0
    },

    imageResolution: vrsStore.modules.app.computed.imageResolution,

    /**
     * Player methods + getters
     */
    timeoutBetweenImages () {
      return 1000 / this.frameRate
    }
  },
  mounted () {
    fetchForecastDataGlobal()
    if (this.$route.query.edit === 'true') this.dashboardStatus = 'update'
  },
  beforeRouteUpdate (to, from, next) {
    if (this.$route.query.edit === 'true') this.dashboardStatus = 'update'
    next()
  },
  methods: {
    cannotWidgetDisplaySync (itemType) {
      return [
        'forecast',
        'event-overview',
        'compare-sessions',
        'player',
        'ground-station-chart-multi-station',
        'ground-station-chart',
        'unknown'
      ].includes(itemType)
    },

    /**
     * Update the selected range in URL (referenceDate, length)
     * Then, the router will update storeApp accordingly
     * and the selectedRangeLocal will be updated in store
     * and will be dispatched to all the components that need it
     */
    updateSelectedRangeLocal (newRange: [string, string], isLive: boolean): void {
      const from = convertDate(newRange[0])
      const to = convertDate(newRange[1])
      const length = Math.round(to.diff(from, 'minutes').minutes)
      const query: AppQS = { ...this.$route.query, length }
      if (!isLive) {
        query.referenceDate = newRange[1]
      } else {
        delete query.referenceDate
      }
      this.$router.push({
        path: this.$route.path,
        query: query as Dictionary<string | string[]>
      })
    },

    updateZoom (newZoom) {
      if (!newZoom) return
      this.$router.push({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          zoomTimeline: newZoom
        }
      })
    },

    changeAnimationLength (newValue: number) {
      if (this.animationLengths.indexOf(vrsStore.state.app.data.animationLength) !== -1) {
        preferences.defaultAnimationLength = newValue
      }
      this.$router.push({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          length: newValue
        }
      })
    },

    goLive () {
      // refresh the current event, and so get the latest radar activity periods + obs°
      vrsStoreEvent.actions.fetchEvent(vrsStore.state.event.data.id)
      // just keep the length if it exist
      const query: AppQS = {}
      if (this.$route.query.length) {
        query.length = parseInt(this.$route.query.length as string)
      }
      delete query.mode
      this.$router.push({
        path: this.$route.path,
        query: query as Dictionary<string | string[]>
      })
    },

    goSession (session) {
      const { start, end } = session
      const startDate = convertDate(start)
      const endDate = convertDate(end)
      const length = endDate.diff(startDate).as('minutes') + 60
      const referenceDate = stringifyDate(endDate.plus({ minutes: 30 }))
      this.$router.push({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          length: length.toString(),
          referenceDate
        }
      })
    },

    getData (type: string, isLinkToPlayer = false) {
      switch (type) {
        case 'forecast-rain-information':
          if (isLinkToPlayer) {
            return this.dataPlayerRainForecast ? this.dataPlayerRainForecast.rain : this.stateForecast.data.rain
          }
          return this.stateForecast.data.rain
        case 'service-information':
          if (isLinkToPlayer) {
            return this.dataPlayerInformation
          }
          return this.stateInformation.data
        case 'weather-station-listing':
        case 'weather-station-listing-multi-station':
          if (isLinkToPlayer) {
            return this.stateWeatherStation?.dataByTimestamp?.map(wsd => (wsd[this.currentTime.local]))
          }
          return this.stateWeatherStation.latest
        case 'weather-radar-map':
          if (isLinkToPlayer) {
            return this.stateWeatherStation?.dataByTimestamp?.map(wsd => (wsd[this.currentTime.local]))
          }
          return this.stateWeatherStation.latest
      }
    },

    refreshReplayData () {
      if (!this.currentDashboard || this.currentDashboard.layout.length === 0 || !this.currentTime || !this.stateForecast?.apiData) return

      let hasPlayer = false
      let replayRain = false
      let replayInfo = false
      this.currentDashboard.layout.forEach(widget => {
        if (widget.type === 'player') hasPlayer = true
        if (widget.params.syncedWithPlayer && widget.type === 'forecast-rain-information') replayRain = true
        if (widget.params.syncedWithPlayer && widget.type === 'service-information') replayInfo = true
      })

      if (hasPlayer) {
        if (replayRain) {
          this.dataPlayerRainForecast = {
            ...filterForecastDataByDate(
              this.stateForecast.apiData,
              this.currentTime.luxonDate,
              this.stateEvent.data.timezone,
              this.stateEvent.data.sessions,
              this.stateConfig.data
            )
          }
        }
        if (replayInfo) {
          const infosDataBeforeCurrentTime = this.stateInformation.all.filter(i => i.datestring <= this.currentTime.local)
          if (infosDataBeforeCurrentTime) {
            this.dataPlayerInformation = infosDataBeforeCurrentTime
          } else {
            this.dataPlayerInformation = null
          }
        }
      }
    },

    /**
     * Dashboard management methods
     */
    openDashboard () {
      this.dashboardStatus = 'update'
    },

    closeDashboard () {
      this.dashboardStatus = 'read'
    },

    addNewWidget () {
      // Add a new item. It must have a unique key!
      this.currentWidgetInEdition = {
        x: 0, // begin of the line
        y: this.currentDashboard.layout.length + (this.colNum || defaultColNum), // puts it at the bottom
        w: 4,
        h: 4,
        i: this.currentDashboard.layout.length,
        title: 'Unknown widget',
        type: 'unknown',
        params: {},
        styles: { padding: true, border: true, displayTitle: true }
      }
      this.currentDashboard.layout.push(this.currentWidgetInEdition)
    },

    removeCurrentWidget () {
      const indexOfCurrentWidget = this.currentDashboard.layout.findIndex(w => w.i === this.currentWidgetInEdition.i)
      if (indexOfCurrentWidget > -1) {
        this.currentDashboard.layout.splice(indexOfCurrentWidget, 1)
      }
      // recompute all `i` variable
      this.currentDashboard.layout.forEach((w, index) => { w.i = index })
      this.currentWidgetInEdition = null
    },

    async saveDashboard () {
      // this.currentDashboard.label = (this.$refs.h1DashboardLabel as HTMLElement).innerText
      const dashboards = [
        ...vrsStore.modules.auth.state.data.user.dashboards
      ]
      dashboards[this.dashboardIndex] = { ...this.currentDashboard }
      await vrsStore.modules.auth.actions.saveDashboards(dashboards)
      this.dashboardStatus = 'read'
    },

    async removeDashboard () {
      const dashboards = [
        ...vrsStore.modules.auth.state.data.user.dashboards
      ]
      dashboards.splice(this.dashboardIndex, 1)
      await vrsStore.modules.auth.actions.saveDashboards(dashboards)
      this.$router.push(vrsStore.modules.auth.computed.defaultHomePage())
    },

    changeSyncedWithPlayer (item): void {
      const currentWidget = this.currentDashboard.layout.find(widget => widget.i === item.i)
      currentWidget.params.syncedWithPlayer = !currentWidget.params.syncedWithPlayer
    },

    // Player
    fastBackward () {
      vrsStore.state.time.indexTimesToDisplayUTC = 0
    },

    backward () {
      if (vrsStore.state.time.indexTimesToDisplayUTC > 1) {
        vrsStore.state.time.indexTimesToDisplayUTC--
      }
    },

    forward () {
      if (vrsStore.state.time.indexTimesToDisplayUTC < this.timesToDisplayUTC.length - 1) {
        vrsStore.state.time.indexTimesToDisplayUTC++
      }
    },

    fastForward () {
      vrsStore.state.time.indexTimesToDisplayUTC = this.timesToDisplayUTC.length - 1
    },

    goToIndex (i) {
      if (i > 0 && i < this.timesToDisplayUTC.length) {
        vrsStore.state.time.indexTimesToDisplayUTC = i
      }
    },

    togglePlay () {
      this.isPlaying = !this.isPlaying
      this.isPlaying ? this.play() : this.stop()
    },

    play () {
      vrsStore.state.time.indexTimesToDisplayUTC =
          (vrsStore.state.time.indexTimesToDisplayUTC + 1) % this.timesToDisplayUTC.length
      this.timeoutId = setTimeout(() => {
        this.play()
      }, this.timeoutBetweenImages)
    },

    stop () {
      clearTimeout(this.timeoutId)
    },

    getGroundStationChartTitle (item: VueGridItem) {
      const itemStationDataset: ChartDataset = item.params.stationDataset as ChartDataset
      let title = item.title
      if (!itemStationDataset) return title
      if (itemStationDataset.datasetRainIntensity) {
        const stationIndex: number = item.params.stationIndex as number
        if (
          vrsStore.state.weatherStation.data &&
        stationIndex < vrsStore.state.weatherStation.data.length
        ) {
          const stationData = vrsStore.state.weatherStation.data[stationIndex]
          if (stationData) {
            const datasets = getDatasets(stationData)
            if (datasets) {
              title += ' Total for period : ' + datasets.rainIntensityTotal + 'mm'
            }
          }
        }
      }
      return title
    },

    getStationListingTitle (item, stationName) {
      const data = this.getData(item.type, item.params.syncedWithPlayer)
      const stationIndex = parseInt(stationName.replace('station', ''), 10) - 1
      const stationData = data[stationIndex]
      if (stationData?.timeToDisplay) {
        return `Station ${stationIndex + 1} at ${stationData.timeToDisplay}`
      } else {
        return `Station ${stationIndex + 1} : no data available`
      }
    },

    getLabelStyle (stationName) {
      let stationIndex = 0
      switch (stationName) {
        case 'station1':
          stationIndex = 0
          break
        case 'station2':
          stationIndex = 1
          break
        case 'station3':
          stationIndex = 2
          break
      }
      return `color: ${seriesColor[stationIndex]}`
    },

    /**
     * Chart widget
     */
    getGroundStationChartDataset (item: VueGridItem) {
      // console.log(item)
      // console.log(item.params)
      const stationIndex: number = item.params.stationIndex as number
      const itemStationDataset: ChartDataset = item.params.stationDataset as ChartDataset || {
        datasetAirTemp: false,
        datasetTrackTemp: false,
        datasetWindAvg: false,
        datasetWindGust: true,
        datasetHumidity: false,
        datasetPressure: false,
        datasetRainIntensity: true,
        datasetWindDirection: false
      }
      if (
        vrsStore.state.weatherStation.data &&
        stationIndex < vrsStore.state.weatherStation.data.length
      ) {
        const stationData = vrsStore.state.weatherStation.data[stationIndex]
        if (stationData) {
          const datasets = getDatasets(stationData)
          if (!datasets) return null

          const chartDataset = {
            plotlyDataset: [],
            twoYAxes: false,
            windDataset: null,
            displayWind: false,
            rangeMin: null,
            rangeMax: null,
            rangeMode: 'normal',
            range2Min: null,
            range2Max: null,
            range2Mode: 'normal'
          }

          /**
           * The ground station widget
           * could have one or two dataset.
           *
           * If the first dataset is the wind direction,
           * we'll not try to add other dataset as the wind direction
           * is represented with a spider chart.
           */
          const selectedDatasets = Object.keys(itemStationDataset)
            .filter(k => itemStationDataset[k] === true)

          if (selectedDatasets.length === 0) return chartDataset

          const firstSelectedDataset = selectedDatasets[0]
          const isFirstSelectedDatasetWindDirection = firstSelectedDataset === 'datasetWindDirection'

          const firstDatasetConfiguration = datasetsConfiguration[firstSelectedDataset]
          const firstDataset = datasets?.[firstDatasetConfiguration.datasetKey]
          firstDatasetConfiguration.datasetBindings.forEach(binding => {
            const { index, ...bindings } = binding
            if (isFirstSelectedDatasetWindDirection) {
              chartDataset.plotlyDataset = firstDataset
            } else {
              const currentDataset = firstDataset[index]
              chartDataset.plotlyDataset.push({
                ...firstDataset[index],
                yaxis: 'y',
                marker: {
                  color: '#b9a26c'
                }
              })
              if (!currentDataset.isAnnotation) {
                setChartDatasetRanges(
                  chartDataset,
                  currentDataset,
                  firstDatasetConfiguration.datasetScale
                )
              }
            }
            Object.keys(bindings).forEach(b => { chartDataset[b] = bindings[b] })
          })

          if (!isFirstSelectedDatasetWindDirection && selectedDatasets.length > 1) {
            const secondDatasetConfiguration = datasetsConfiguration[selectedDatasets[1]]
            const isSameDatasetScale = firstDatasetConfiguration.datasetScale === secondDatasetConfiguration.datasetScale

            const yaxis = isSameDatasetScale ? 'y' : 'y2'
            let rangeMinKey = 'rangeMin'
            let rangeMaxKey = 'rangeMax'
            let rangeModeKey = 'rangeMode'
            if (yaxis === 'y2') {
              chartDataset.twoYAxes = true
              rangeMinKey = 'range2Min'
              rangeMaxKey = 'range2Max'
              rangeModeKey = 'range2Mode'
            }
            const secondDataset = datasets[secondDatasetConfiguration.datasetKey]
            secondDatasetConfiguration.datasetBindings.forEach(binding => {
              const currentDataset = secondDataset[binding.index]
              chartDataset.plotlyDataset.push({
                ...currentDataset,
                yaxis,
                marker: {
                  color: '#03bde4'
                }
              })
              if (!currentDataset.isAnnotation) {
                setChartDatasetRanges(
                  chartDataset,
                  currentDataset,
                  secondDatasetConfiguration.datasetScale,
                  rangeMinKey,
                  rangeMaxKey,
                  rangeModeKey
                )
              }
            })
            /**
             * Management of minimal according axes.
             *
             * We configure this only if there is two series ?
             *
             */
            // console.log(
            //   isSameDatasetScale,
            //   firstDatasetConfiguration.datasetScale,
            //   datasets.minTemperature,
            //   datasets.maxTemperature
            // )
            if (isSameDatasetScale) {
              switch (firstDatasetConfiguration.datasetScale) {
                case 'temperature':
                  chartDataset.rangeMin = datasets.minTemperature - datasets.temperatureAddition
                  chartDataset.rangeMax = datasets.maxTemperature + datasets.temperatureAddition
                  break
                case 'wind':
                  chartDataset.rangeMin = (datasets.minWind - datasets.windAddition) < 0 ? 0 : datasets.minWind - datasets.windAddition
                  chartDataset.rangeMax = datasets.maxWind + datasets.windAddition
                  break
              }
            }
          }
          // console.log(JSON.parse(JSON.stringify(chartDataset)))
          return chartDataset
        }
        return null
      } else {
        return null
      }
    },
    getGroundStationChartMultiStationDataset (item: VueGridItem) {
      const stationSet: string[] = item.params.stationSet as string[]

      const serie = Object.keys(item.params.stationDataset).find(k => item.params.stationDataset[k]) || 'datasetAirTemp'

      const stationIndexes = stationSet.map(s => {
        switch (s) {
          case 'station1':
            return 0
          case 'station2':
            return 1
          case 'station3':
            return 2
        }
      })

      if (vrsStore.state.weatherStation.data
      // stationIndex < vrsStore.state.weatherStation.data.length
      ) {
        const chartDataset = {
          plotlyDataset: [],
          twoYAxes: false,
          windDataset: null,
          displayWind: false,
          rangeMin: null,
          rangeMax: null,
          rangeMode: 'normal',
          range2Min: null,
          range2Max: null,
          range2Mode: 'normal'
        }

        stationIndexes.forEach(function (currentStationIndex) {
          /**
           * If the selected station doesn't exist, continue to the next one
           */
          if (currentStationIndex >= vrsStore.state.weatherStation.data.length) return
          const currentStationData = vrsStore.state.weatherStation.data[currentStationIndex]
          if (currentStationData) {
            const datasets = getDatasets(currentStationData)
            if (!datasets) return null

            /**
             * The ground multi station widget have only one serie,
             * but could have several (one to three) datasets.
             */
            const datasetConfiguration = datasetsConfiguration[serie]
            const currentDataset = datasets?.[datasetConfiguration.datasetKey]
            datasetConfiguration.datasetBindings.forEach(binding => {
              const { index, ...bindings } = binding
              const currentStationDataset = currentDataset[index]
              chartDataset.plotlyDataset.push({
                ...currentStationDataset,
                name: `Station n°${currentStationIndex + 1}`,
                yaxis: 'y',
                marker: {
                  color: seriesColor[currentStationIndex]
                }
              })
              if (!currentStationDataset.isAnnotation) {
                setChartDatasetRanges(
                  chartDataset,
                  currentStationDataset,
                  datasetConfiguration.datasetScale
                )
              }
              Object.keys(bindings).forEach(b => {
                chartDataset[b] = bindings[b]
              })
            })
          }
        })

        return chartDataset
      } else {
        return null
      }
    }
  },
  watch: {
    currentTime: 'refreshReplayData',
    selectedRangeLocal: {
      immediate: true,
      handler (newRange, oldRange): string[] {
        if (!newRange) return
        if (newRange[0] === oldRange?.[0] && newRange[1] === oldRange?.[1]) return
        if (!vrsStore.state.event.data) return
        if (!this.selectedRangeLocal) return
        fetchWeatherStationDataFilteredByDate(
          vrsStore.state.event.data.stations.map(s => s.id.toString()),
          convertDate(this.selectedRangeLocal[0], vrsStore.state.event.data.timezone),
          convertDate(this.selectedRangeLocal[1], vrsStore.state.event.data.timezone)
        )
      }
    },
    'stateEvent.data': {
      immediate: true,
      async handler (newValue: WeatherEvent) {
        if (newValue) {
          await fetchRadarBbox(newValue.id)
          this.centerPreferenceMain = preferences.events?.[vrsStore.state.event.data.id]?.mapMainCenter
        }
      }
    },
    'widgetFromDashboard.type': {
      deep: true,
      handler (newValue, oldValue) {
        const referenceToWidgetFromDashboard = this.widgetFromDashboard
        if (!referenceToWidgetFromDashboard) return
        // if not current widget, we can't edit params / styles
        if (!this.currentWidgetInEdition) return
        // To prevent multiple trigger and
        // to apply only when widget type has already been specified (i.e. not to the creation)
        if (oldValue) {
          let newParams = {}
          let newStyles = {}
          switch (newValue) {
            case 'player':
              newParams = {
                syncedWithPlayer: false
              }
              newStyles = {
                padding: true,
                border: true,
                displayTitle: false
              }
              break
            case 'event-overview':
              newParams = {
                seriesOverview: ['avgAirTemp', 'avgTrackTemp']
              }
              newStyles = {
                padding: true,
                border: true,
                displayTitle: true
              }
              break
            case 'compare-sessions':
              newParams = {
                seriesCompare: ['avgAirTemp', 'avgTrackTemp'],
                ...selectionSessions({
                  defaultSessionA: null,
                  defaultSessionB: null,
                  defaultStationA: 'all',
                  defaultStationB: 'all'
                }, this.stateEvent.sessionsWithStat)
              }
              newStyles = {
                padding: true,
                border: true,
                displayTitle: true
              }
              break
            case 'weather-radar-map':
              newParams = {
                osmOpacity: 1,
                radarOpacity: 1,
                displayLayersWeatherStations: true,
                displayLayersWindArrows: true,
                displayLayersRainpath: true,
                syncedWithPlayer: true
              }
              newStyles = {
                padding: true,
                border: true,
                displayTitle: false
              }
              break
            case 'weather-station-listing':
              newParams = {
                currentStation: 0,
                syncedWithPlayer: false
              }
              newStyles = {
                padding: true,
                border: true,
                displayTitle: true
              }
              break
            case 'weather-station-listing-multi-station':
              newParams = {
                stationSet: ['station1'],
                syncedWithPlayer: false
              }
              newStyles = {
                padding: true,
                border: true,
                displayTitle: true
              }
              break
            case 'ground-station-chart':
              newParams = {
                stationIndex: 0,
                stationDataset: {
                  datasetAirTemp: true,
                  datasetTrackTemp: true,
                  datasetWindAvg: false,
                  datasetWindGust: false,
                  datasetHumidity: false,
                  datasetPressure: false,
                  datasetRainIntensity: false,
                  datasetWindDirection: false
                },
                displaySession: true,
                syncedWithPlayer: false
              }
              newStyles = {
                padding: true,
                border: true,
                displayTitle: true
              }
              break
            case 'ground-station-chart-multi-station':
              newParams = {
                stationSet: ['station1', 'station2', 'station3'],
                stationDataset: {
                  datasetAirTemp: true,
                  datasetTrackTemp: false,
                  datasetWindAvg: false,
                  datasetWindGust: false,
                  datasetHumidity: false,
                  datasetPressure: false,
                  datasetRainIntensity: false
                },
                displaySession: true,
                syncedWithPlayer: false
              }
              newStyles = {
                padding: true,
                border: true,
                displayTitle: true
              }
              break
            case 'forecast':
              newParams = {
                temperatureOverLimit: this.stateConfig.data.LIMIT_FORECAST_HOURLY_TEMPERATURE_AIR,
                chanceOfRainOverLimit: this.stateConfig.data.LIMIT_FORECAST_HOURLY_RAIN_CHANCE || [],
                windOverLimit: this.stateConfig.data.LIMIT_FORECAST_BYDAY_WIND || [],
                gustsOverLimit: this.stateConfig.data.LIMIT_FORECAST_HOURLY_WIND_GUSTS || [],
                humidityOverLimit: this.stateConfig.data.LIMIT_FORECAST_HOURLY_HUMIDITY,
                pressureOverLimit: this.stateConfig.data.LIMIT_FORECAST_HOURLY_PRESSURE,
                trackMinOverLimit: this.stateConfig.data.LIMIT_FORECAST_HOURLY_TEMPERATURE_TRACK_MIN,
                trackMaxOverLimit: this.stateConfig.data.LIMIT_FORECAST_HOURLY_TEMPERATURE_TRACK_MAX
              }
              newStyles = {
                padding: true,
                border: true,
                displayTitle: true
              }
              break
            default:
              newParams = {
                syncedWithPlayer: false
              }
              newStyles = {
                padding: true,
                border: true,
                displayTitle: true
              }
          }

          this.$set(referenceToWidgetFromDashboard, 'params', newParams)
          this.$set(referenceToWidgetFromDashboard, 'styles', newStyles)

          const typeWidget = typesWidgets.find(({ value }) => value === referenceToWidgetFromDashboard.type)
          if (typeWidget.value !== 'unknown') {
            referenceToWidgetFromDashboard.title = typeWidget.label
          } else {
            referenceToWidgetFromDashboard.title = 'Unknown widget'
          }
        }
      }
    }
  }
}
