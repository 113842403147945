






















import { PropOptions } from 'vue'
import { ChartDataset } from '@/views/CustomDashboard/definition'

export default {
  props: {
    stationDataset: {
      type: Object,
      required: true
    } as PropOptions<ChartDataset>,
    allowWindDirectionSet: {
      type: Boolean,
      default: true
    },
    onlyOneDataset: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    datasets () {
      const defaultDataSet = [{
        propName: 'datasetAirTemp',
        label: 'T° Air'
      }, {
        propName: 'datasetTrackTemp',
        label: 'T° Track'
      }, {
        propName: 'datasetWindAvg',
        label: 'Wind Avg'
      }, {
        propName: 'datasetWindGust',
        label: 'Wind Gust'
      }, {
        propName: 'datasetHumidity',
        label: 'Humidity'
      }, {
        propName: 'datasetPressure',
        label: 'Pressure'
      }, {
        propName: 'datasetRainIntensity',
        label: 'Rain intensity'
      }, {
        propName: 'datasetWindDirection',
        label: 'Wind direction (exclusive)'
      }]
      if (this.allowWindDirectionSet) {
        return defaultDataSet
      } else {
        return defaultDataSet.slice(0, defaultDataSet.length - 1)
      }
    }
  }
}
